@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import 'animate.css/animate.min.css';


html {
    @apply font-calibri; /* Apply the Calibri font globally */
  }